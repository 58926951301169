.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: background 0.3s linear;
}
.main-content > .container {
  transition: height 0.3s linear;
  /* height: 625px; */
}
.root-cards-revealed .main-content {
  background: url(/img/bkg-two.jpg) no-repeat center center scroll; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
}

.title2 {
  display: block;
  transition: max-height 0.3s ease-out;
  max-height: 300px;
}


.title-wrapper {
  position: relative;
  height: 200px;
  transition: height 0.3s linear;
}
/* .title {
  position: absolute;
  left: 0; right: 0;
  opacity: 1;
  transition: opacity 0.3s linear;
  margin: 0;
} */
.title-result {
  position: absolute;
  left: 0; right: 0;
  top: 0; right: 0;
  opacity: 0;
  transition: opacity 0.3s 1s linear;
  margin: 0;
}
.title-result h2 {
  text-transform: uppercase;
  color: #c1d9ef;
  font-weight: 300;
}
.root-cards-revealed .title-wrapper {
  height: 100px;
}

.hide-on-reveal  {
  transition: opacity height 0.3s linear;
}

.card-back {
  transition: opacity 0.3s linear;
}

.card-front {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.card-selected .card-back {
  opacity: 0;
}

.card-selected .card-front {
  opacity: 1;
}

.analiza-wrapper {
  z-index: 0;
  pointer-events: none;
  margin-top: 30px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s linear;
}
.root-cards-revealed .analiza-wrapper {
  position: initial;
  opacity: 1;
  pointer-events: initial;
}

.note {
  transition: opacity 0.3s linear;
  opacity: 0;
  height: 0;
}
.note p {
  color: grey;
  font-size: 12px;
  line-height: 11px;
}
.root-cards-revealed .note {
  opacity: 1;
  height: auto;
}

.root-cards-revealed .hide-on-reveal {
  opacity: 0;
  height: 0;
  margin: 0 !important;
}

@media (max-width:1023px) {
  .navbar-header {
    display: flex;
  }
  .navbar-brand {
    margin: auto;
  }
  .main-content > .container {
    width: auto !important;
    /* height: 720px; */
  }
  .root-cards-revealed .main-content > .container {
    height: auto;
    min-height: auto;
  }
  .title-wrapper {
    height: 195px;
    position: initial;
  }
  .title-wrapper .title {
    position: initial;
  }
  .title-wrapper .title-result {
    position: absolute;
  }
  .title-wrapper .title h1 {
    font-size: 28px;
  }
  .title-wrapper .title h4 {
    font-size: 18px;
  }
  .title-wrapper .title h5 {
    font-size: 18px;
  }
  .title-wrapper .title-result h2 {
    font-size: 28px;
  }
  .root-cards-revealed .title-wrapper {
    height: initial;
  }
  .root-cards-revealed .title-wrapper .title {
    position: absolute;
  }
  .root-cards-revealed .title-wrapper .title-result {
    position: initial;
  }
  .btn-tarot {
    font-size: 16px;
    margin: 16px 0;
  }

  h6 {
    font-size: 15px;
  }


  .analiza-wrapper {
    padding: 0;
    margin-top: 0px;
    position: absolute;
    bottom: 0;
    pointer-events: none;
  }
  .root-cards-revealed .analiza-wrapper {
    position: initial;
  }

  .analiza-wrapper > .container > div {
    line-height: 1;
    padding: 0;
  }

  .root-cards-revealed .note {
    /**margin-top: 40px !important**/;
  }
  .note {
    padding: 0;
  }
}

.pulse-button {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 25px;
  box-shadow: 0 0 0 0 rgba(255,182,193, .5);
  -webkit-animation: pulse 2.5s infinite;
}
.pulse-button:hover {
  -webkit-animation: none;
}

@-webkit-keyframes pulse {
  0% {
  @include transform(scale(.9));
  }
  70% {
  @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(255,182,193, 0);
  }
  100% {
  @include transform(scale(.9));
    box-shadow: 0 0 0 0 rgba(255,182,193, 0);
  }
}

.padding-unset {
  padding: unset !important;
}